import React, { useState, Fragment, useEffect } from 'react'
import {
    Grid,
    TextField,
    Button,
    Paper,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    makeStyles,
    Backdrop,
    CircularProgress,
    Tooltip,
    IconButton,
    ListItemText,
    Checkbox,
    Input,
    OutlinedInput,
    Modal

} from '@material-ui/core';
import DateFnsUtils from "@date-io/date-fns";
import {
    DateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import ClearIcon from "@material-ui/icons/Clear";
import { useStyles } from '../../styles/style';
import axios from 'axios';
// import DateFnsUtils from "@date-io/date-fns";
// import {
//     DateTimePicker,
//     MuiPickersUtilsProvider
// } from "@material-ui/pickers";
import CancelIcon from '@material-ui/icons/Cancel';
// import ClearIcon from "@material-ui/icons/Clear";
import cogoToast from 'cogo-toast';
import { banner_content_type } from './../bucket/objects'
import Switch from "react-switch";
import InfoIcon from "@material-ui/icons/InfoRounded"
import MultiLanguageModal from '../Reusable/multiLanguageModal';


const useStyle = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 3,
        color: '#fff',
    },
}));



export default function CreateBanner(props) {
    const classes = useStyles();
    const backDropClass = useStyle();
    const [title, setTitle] = useState("");
    const [date, setDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [subTitle, setSubTitle] = useState("");
    const [cta, setCta] = useState("");
    const [cta2, setCta2] = useState("");
    const [lt, setLTToggle] = useState(false);
    const [pws, setPWSToggle] = useState(false);
    const [st, setSTToggle] = useState(false);
    const [playTrailer, setplayTrailerToggle] = useState(false);
    const [bucketStyle, setBucketStyle] = useState("");
    const [contentType, setContentType] = useState("");
    const [contentId, setContentId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [language, setLanguage] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [langList, setLanglist] = useState([]);
    const [musicVideo, setMusicVideo] = useState("")
    const [stencil, setStencil] = useState([])
    const [thumbnail, setThumbnail] = useState([])
    const toggle = () => setModal(!modal);
    const [contentRights, setContentRights] = useState([{ id: "android", title: "android" }, { id: "ios", title: "ios" }, { id: "web", title: "web" }, { id: "tv", title: "tv" }, { id: "oppo", title: "oppo" }])
    const [device, setDevice] = useState([])
    const [ctaArray, setCtaArrayRights] = useState([{ id: "Play Now", title: "Play Now" }, { id: "Explore Now", title: "Explore Now" }])
    const [cta2Array, setCta2ArrayRights] = useState([{ id: "Share", title: "Share" }])
    const [englishLang, setEnglishLang] = useState("");
    const [hindiLang, setHindiLang] = useState("");
    const [marathiLang, setMarathiiLang] = useState("");
    const [kannadaLang, setKannadaLang] = useState("");
    const [teluguLang, setTeluguLang] = useState("");
    const [punjabiLang, setPunjabiLang] = useState("");
    const [tamilLang, setTamilLang] = useState("");
    const [gujaratiLang, setGujaratiLang] = useState("");

    const [malayalamLang, setMalayalamLang] = useState("");
    const [bengaliLang, setBengaliLang] = useState("");
    const [odisaLang, setOdisaLang] = useState("");
    const [urduLang, setUrduLang] = useState("");
    const [sinhalaLang, setSinhalaLang] = useState("");
    const [khmerLang, setKhmerLang] = useState("");
    const [arabicLang, setArabicLang] = useState("");
    const [burmeseLang, setBurmeseLang] = useState("");
    const [afganiLang, setAfganiLang] = useState("");
    const [user, setUser] = useState("2");
    const [modal, setModal] = useState(false);

    const [url, seturl] = useState("")
    const [continueToggle, setcontinueToggle] = useState(false)
    const [externalToggle, setexternalToggle] = useState(false)

    const [listMusicVideo, setListMusicVideo] = useState([{ id: "music", title: "Music" }, { id: "video", title: "Video" }, { id: "payment", title: "Payment" }, { id: "game", title: "Game" }])

    // const [listCta, setList] = useState([{
    //     id: "Play Now", title: [{
    //         hi: "प्ले नाउ",
    //         mr: "प्ले नौ",
    //         en: "Play Now",
    //         kn: "ಪ್ಲೇ ನೌ",
    //         te: "ప్లే నౌ",
    //         pa: "ਪਲੇ ਨੌ",
    //         ta: "பழைய நொவ்",
    //         gu: "પ્લે નોવા",
    //         or: "ପ୍ଲେ ନବ",
    //         ur: "پلے ناؤ",
    //         bn: "প্লে নাউ",
    //         ml: "പ്ലേയ് നൗ",
    //         si: "ප්ලය් නොව",
    //         ar: "بلاي نو",
    //         af: "اوس لوبه وکړئ",
    //         bur: "ယခုကစားပါ။",
    //         khm: "លេង​ឥឡូវ"
    //     }]
    // }, {
    //     id: "Watch Now", title: [{
    //         hi: "वाच नाउ",
    //         mr: "वाटच नौ",
    //         en: "Watch Now",
    //         kn: "ವಾಚ್ ನೌ",
    //         te: "వాచ్ నౌ",
    //         pa: "ਵਤਚ ਨੌ",
    //         ta: "வாட்ச் நொவ்",
    //         gu: "વચ્છ નોવા",
    //         or: "ବାଟଚ ନବ",
    //         ur: "واچ ناؤ",
    //         bn: "ওয়াচ নাউ",
    //         ml: "വാച്ച് നൗ",
    //         si: "වට්ච් නොව",
    //         ar: "واتش نو",
    //         af: "اوس یې وګوره",
    //         bur: "ယခုကြည့်ရှုပါ။",
    //         khm: "មើលឥឡូវនេះ"
    //     }]
    // }])
    // const [open, setOpen] = useState(false);

    const toggleLanguage = () => {
        console.log("Opening Modal...");
        setModal(!modal);
    };

    // const handleClose = () => {
    //     setOpen(false);
    // };




    const fetchLanguage = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/language?storeId=${localStorage.getItem("storeId")}`).then(res => {
            setLanglist(res.data.result.result)
        }).catch(
            err => {
                console.log(err)
            }
        )
    }

    useEffect(() => {
        fetchLanguage()
    }, [])

    const handleContentId = (e) => {
        setContentId(e.target.value)

    }

    const handleContentType = (e) => {
        setContentType(e.target.value)

        console.log('===', e.target.value)

        if (e.target.value == "Podcast Audio Album") {
            setCtaArrayRights([{ id: "Play Now", title: "Play Now" }, { id: "Explore Now", title: "Explore Now" }])
            setCta2ArrayRights([{ id: "Download", title: "Download" }])
        }
        else if (e.target.value == "Podcast Audio Track") {
            setCtaArrayRights([{ id: "Play Now", title: "Play Now" }])
            setCta2ArrayRights([{ id: "Download", title: "Download" }])
        }
        else if (e.target.value == "Movie") {
            setCtaArrayRights([{ id: "Watch Now", title: "Watch Now" }, { id: "Subscribe Now", title: "Subscribe Now" }, { id: "Rent Now", title: "Rent Now" }, { id: "Explore Now", title: "Explore Now" }])
            setCta2ArrayRights([{ id: "Download", title: "Download" }])
        }
        else if (e.target.value == "TV Series") {
            setCtaArrayRights([{ id: "Watch Now", title: "Watch Now" }, { id: "Subscribe Now", title: "Subscribe Now" }, { id: "Explore Now", title: "Explore Now" }])
            setCta2ArrayRights([{ id: "Share", title: "Share" }, { id: "Download", title: "Download" }])
        }
        else if (e.target.value == "TV Series Episode") {
            setCtaArrayRights([{ id: "Play Now", title: "Play Now" }, { id: "Subscribe Now", title: "Subscribe Now" }])
            setCta2ArrayRights([{ id: "Share", title: "Share" }, { id: "Download", title: "Download" }])
        }
        else if (e.target.value == "Audio Album") {
            setCtaArrayRights([{ id: "Play Now", title: "Play Now" }, { id: "Explore Now", title: "Explore Now" }])
            setCta2ArrayRights([{ id: "Download", title: "Download" }])
        }
        else if (e.target.value == "Short Films") {
            setCtaArrayRights([{ id: "Watch Now", title: "Watch Now" }, { id: "Explore Now", title: "Explore Now" }])
            setCta2ArrayRights([{ id: "Download", title: "Download" }])
        }
        else if (e.target.value == "Movie Videos") {
            setCtaArrayRights([{ id: "Watch Now", title: "Watch Now" }, { id: "Explore Now", title: "Explore Now" }])
            setCta2ArrayRights([{ id: "Share", title: "Share" }, { id: "Download", title: "Download" }])
        }
        else if (e.target.value == "Events and Broadcasts Album") {
            setCtaArrayRights([{ id: "Watch Now", title: "Watch Now" }, { id: "Explore Now", title: "Explore Now" }])
            setCta2ArrayRights([{ id: "Share", title: "Share" }, { id: "Download", title: "Download" }])
        }
        else if (e.target.value == "Events and Broadcasts Video") {
            setCtaArrayRights([{ id: "Watch Now", title: "Watch Now" }, { id: "Explore Now", title: "Explore Now" }])
            setCta2ArrayRights([{ id: "Share", title: "Share" }, { id: "Download", title: "Download" }])
        }
        else if (e.target.value == "charts") {
            setCtaArrayRights([{ id: "Play Now", title: "Play Now" }, { id: "Explore Now", title: "Explore Now" }])
            setCta2ArrayRights([{ id: "Download", title: "Download" }])
        }
        else if (e.target.value == "playlist") {
            setCtaArrayRights([{ id: "Play Now", title: "Play Now" }, { id: "Explore Now", title: "Explore Now" }])
            setCta2ArrayRights([{ id: "Download", title: "Download" }])
        }
        else if (e.target.value == "Artist") {
            setCtaArrayRights([{ id: "Play Now", title: "Play Now" }, { id: "Explore Now", title: "Explore Now" }])
            setCta2ArrayRights([{ id: "Share", title: "Share" }])
        }
        else if (e.target.value == "Live Event") {
            setCtaArrayRights([{ id: "Remind Me", title: "Remind Me" }, { id: "Book Now", title: "Book Now" }])
            setCta2ArrayRights([{ id: "Share", title: "Share" }])
        }
        else if (e.target.value == "hungama gold") {
            setCtaArrayRights([{ id: "Buy Now", title: "Buy Now" }])
            setCta2ArrayRights([{ id: "View Plans", title: "View Plans" }])
        }
        else if (e.target.value == "Audio Track") {
            setCtaArrayRights([{ id: "Play Now", title: "Play Now" }])
            setCta2ArrayRights([{ id: "Download", title: "Download" }])
        }
        else if (e.target.value == "BrandHub") {
            setCtaArrayRights([{ id: "Play Now", title: "Play Now" }, { id: "Explore Now", title: "Explore Now" }])
            setCta2ArrayRights([{ id: "Share", title: "Share" }])
        }
        else if (e.target.value == "Mobile Game") {
            setCtaArrayRights([{ id: "Play Now", title: "Play Now" }])
            setCta2ArrayRights([{ id: "Share", title: "Share" }])
        }
        else {
            setCtaArrayRights([{ id: "Play Now", title: "Play Now" }, { id: "Explore Now", title: "Explore Now" }])
            setCta2ArrayRights([{ id: "Share", title: "Share" }])
        }
    }

    const onEnterContentId = (e) => {
        if (contentId !== "" && e.key === "Enter" || e.key === "Tab") {
            axios.get(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/solr/${contentId}?storeId=${localStorage.getItem("storeId")}`).then(res => {
                console.log(res.data)
                setDisplayName(res.data.data.response.docs[0].name)
                if (contentType == "Movie") {
                    if (res && res.data && res.data.data && res.data.data.response && res.data.data.response.docs && res.data.data.response.docs[0] && res.data.data.response.docs[0].propertyid) {
                        cogoToast.success(`Trailer exists for movie : ${res.data.data.response.docs[0].name}`)
                    }
                }
                else if (contentType == "TV Series") {
                    if (res && res.data && res.data.data && res.data.data.response && res.data.data.response.docs && res.data.data.response.docs[0] && res.data.data.response.docs[0].attribute_trailer_id) {
                        cogoToast.success(`Trailer exists for movie : ${res.data.data.response.docs[0].name}`)
                    }
                }
            }).catch(err => {
                console.log(err)
                cogoToast.error("Wrong ContentId")
            })
        }


    }


    const postApi = async (data) => {
        console.log("data", data);

        const fd = new FormData();
        fd.append("title", data.title);
        fd.append("tag", JSON.stringify(data.tag));
        fd.append("ctatitle", data.ctatitle);
        fd.append("cta2", data.cta2);
        fd.append("bucketStyle", "50");
        fd.append("language", data.language);
        fd.append("type", data.type);
        fd.append("contentType", data.contentType);
        fd.append("contentId", data.contentId);
        fd.append("displayName", data.displayName);
        fd.append('storeId', localStorage.getItem("storeId"))
        fd.append('url', data.url)
        fd.append('dlinkStatus', data.dlinkStatus)
        fd.append('external', data.external)
        fd.append('startDate', data.startDate)
        fd.append('endDate', data.endDate)
        fd.append('user', data.user)
        fd.append('playWSound', data.playWSound)
        fd.append('loopTrailer', data.loopTrailer)
        fd.append('setTime', data.setTime)
        fd.append('playTrailer', data.playTrailer)
        fd.append('device', JSON.stringify(data.device))
        for (let index = 0; index < data.image.length; index++) {
            const element = data.image[index];
            fd.append('image', element)
        }

        for (let index = 0; index < data.stencil.length; index++) {
            const element = data.stencil[index];
            fd.append('stencil', element)
        }
        console.log("fd========", fd);

        await axios.post(`${process.env.REACT_APP_CMS_PLAYLIST_IP}/v1/banner`, fd)
            .then(
                res => {
                    setTitle("")
                    setSubTitle("")
                    setBucketStyle("")
                    setContentType("");
                    setDisplayName("");
                    setLanguage("")
                    setStencil([])
                    setThumbnail([])
                    setContentId("")
                    setIsLoading(false)
                    cogoToast.success("success")
                }
            ).catch(err => {
                cogoToast.error(err.response.data.message);
                setIsLoading(false)

            })

    }


    const onSubmit = () => {
        let obj = {
            // title: {
            //     hi: hindiLang,
            //     mr: marathiLang,
            //     en: englishLang,
            //     kn: kannadaLang,
            //     te: teluguLang,
            //     pa: punjabiLang,
            //     ta: tamilLang,
            //     gu: gujaratiLang,
            //     or:odisaLang,
            //     ur:urduLang,
            //     bn:bengaliLang,
            //     ml:malayalamLang,
            //     si:sinhalaLang,
            //     ar:arabicLang,
            //         af:afganiLang,
            //         bur:burmeseLang,
            //         khm:khmerLang
            // },
            title: title,
            tag: [{
                default: englishLang,
                hi: hindiLang,
                mr: marathiLang,
                en: englishLang,
                kn: kannadaLang,
                te: teluguLang,
                pa: punjabiLang,
                ta: tamilLang,
                gu: gujaratiLang,
                or: odisaLang,
                ur: urduLang,
                bn: bengaliLang,
                ml: malayalamLang,
                si: sinhalaLang,
                ar: arabicLang,
                af: afganiLang,
                bur: burmeseLang,
                khm: khmerLang
            }],
            ctatitle: cta,
            cta2: cta2,
            bucketStyle: bucketStyle,
            contentType: contentType,
            contentId: contentId,
            displayName: displayName,
            stencil: stencil,
            image: thumbnail,
            language: language,
            type: musicVideo,
            storeId: localStorage.getItem("storeId"),
            url: url,
            dlinkStatus: str2bool(continueToggle),
            external: str2bool(externalToggle),
            startDate: startDate,
            endDate: endDate,
            user: user,
            playWSound: pws,
            loopTrailer: lt,
            setTime: st,
            playTrailer: playTrailer,
            device: device
        }
        // console.log('oobj',obj)
        postApi(obj)
        setIsLoading(true)
    }
    const names = [
        "AVOD",
        "FVOD",
        "SVOD",
        "TVOD",
        "AMOD"
    ];

    const onClear = () => {
        setTitle("")
        setSubTitle("")
        setBucketStyle("")
        setContentType("");
        setDisplayName("");
        setLanguage("")
        setStencil([])
        setThumbnail([])
        setContentId("")
    }
    const handleChangeToggle = (e) => {
        console.log('ee', e)
        setcontinueToggle(e)
    }
    const handleExternalToggle = (e) => {
        console.log('ee', e)
        setexternalToggle(e)
    }
    const handleLTToggle = (e) => {
        console.log('ee', e)
        setLTToggle(e)
    }

    const handlePWSToggle = (e) => {
        console.log('ee', e)
        setPWSToggle(e)
    }

    const handleplayTrailerToggle = (e) => {
        console.log('play_trailer', e)
        setplayTrailerToggle(e)
        if (e === false) {
            setLTToggle(e)
            setPWSToggle(e)
        }
    }

    const handleSTToggle = (e) => {
        console.log('ee', e)
        setSTToggle(e)
    }

    const onHandleChangeData = (e) => {
        console.log('ee', e.target.value)
        seturl(e.target.value)
    }
    const deleteStencil = (e, i) => {
        let newArray = stencil.filter((ele, index) => {
            return index !== i;
        })

        setStencil(newArray)
    }
    var str2bool = (value) => {
        if (value && typeof value === "string") {
            if (value.toLowerCase() === "true") return true;
            if (value.toLowerCase() === "false") return false;
        }
        return value;
    }
    const deleteBackground = (e, i) => {
        let newArray = thumbnail.filter((ele, index) => {
            return index !== i;
        })

        setThumbnail(newArray)
    }





    return (
        <Fragment>
            <Paper component={Box} p={4}>
                <Backdrop className={backDropClass.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div>
                    <h1 className="text-center mb-5" >Add Banner</h1>
                    <Grid container style={{ marginLeft: "20px" }}>
                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="title" value={title} onChange={(e) => setTitle(e.target.value)}>
                            </TextField>
                            {/* <Button style={{ height: "56px",marginTop:"6px",marginLeft:"-6px" }} variant="contained" onClick={toggle}>Multi-Language</Button> */}
                        </Grid>
                        <Grid item md={6}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={musicVideo}
                                    onChange={(e) => setMusicVideo(e.target.value)}
                                    label="Language"
                                >
                                    {
                                        listMusicVideo && listMusicVideo.length > 0 ?
                                            listMusicVideo.map((element, id) => {

                                                return (
                                                    <MenuItem key={id} value={element.id}>{element.title}</MenuItem>
                                                )
                                            }) :
                                            <MenuItem disabled value="no pages">No data</MenuItem>

                                    }

                                </Select>

                            </FormControl>

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <FormControl variant="outlined" className={classes.formControl} >
                                <InputLabel id="demo-simple-select-outlined-label">Content Type</InputLabel>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={contentType}
                                    onChange={handleContentType}
                                    label="Content type"
                                >
                                    {
                                        banner_content_type && banner_content_type.length > 0 ?
                                            banner_content_type.map((element, id) => {

                                                return (
                                                    <MenuItem key={id} value={element.Description}>{element.Description}</MenuItem>
                                                )
                                            }) :
                                            <MenuItem disabled value="no pages">No Content Type</MenuItem>

                                    }

                                </Select>

                            </FormControl>



                        </Grid>
                        <Grid item md={6}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">CTA</InputLabel>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={cta}
                                    onChange={(e) => setCta(e.target.value)}
                                    label="Language"
                                >
                                    {
                                        ctaArray && ctaArray.length > 0 ?
                                            ctaArray.map((element, id) => {

                                                return (
                                                    <MenuItem key={id} value={element.title}>{element.title}</MenuItem>
                                                )
                                            }) :
                                            <MenuItem disabled value="no pages">No data</MenuItem>

                                    }
                                    {/* <MenuItem  value="Play Now">Play Now</MenuItem>
                    <MenuItem  value="Watch Now">Watch Now</MenuItem> */}
                                </Select>

                            </FormControl>

                        </Grid>
                        {/* <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="CTA" value={subTitle} onChange={(e) => setSubTitle(e.target.value)}>
                            </TextField>
                        </Grid> */}

                        <Grid item md={6}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">2nd CTA</InputLabel>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={cta2}
                                    onChange={(e) => setCta2(e.target.value)}
                                    label="Language"
                                >
                                    {
                                        cta2Array && cta2Array.length > 0 ?
                                            cta2Array.map((element, id) => {

                                                return (
                                                    <MenuItem key={id} value={element.title}>{element.title}</MenuItem>
                                                )
                                            }) :
                                            <MenuItem disabled value="no pages">No data</MenuItem>

                                    }
                                    {/* <MenuItem  value="Follow">Follow</MenuItem>
                    <MenuItem  value="Download">Download</MenuItem>
                    <MenuItem  value="Watchlist">Watchlist</MenuItem>
                    <MenuItem  value="Watch later">Watch later</MenuItem>
                    <MenuItem  value="Favorited">Favorited</MenuItem>
                    <MenuItem  value="Follow Artist">Follow Artist</MenuItem>
                    <MenuItem  value="View Plans">View Plans</MenuItem>
                    <MenuItem  value="Share">Share</MenuItem> */}
                                </Select>

                            </FormControl>

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextField type="number" InputProps={{ inputProps: { min: 0, max: 50 } }} variant="outlined" className={classes.inputField} width="25" required label="Bucket Style" name="bucketStyle" value={bucketStyle} onChange={(e) => setBucketStyle(e.target.value)} >
                            </TextField>
                        </Grid>

                        {/* <Grid item md={6}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Language</InputLabel>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={language}
                                    onChange={(e) => setLanguage(e.target.value)}
                                    label="Language"
                                ><MenuItem value="Default">Default</MenuItem>
                                    {
                                        langList && langList.length > 0 ?
                                            langList.map((element, id) => {

                                                return (
                                                    <MenuItem key={id} value={element.name}>{element.name}</MenuItem>
                                                )
                                            }) :
                                            <MenuItem disabled value="no pages">No Pages</MenuItem>

                                    }

                                </Select>

                            </FormControl>

                        </Grid> */}

                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" className={classes.inputField} width="25" required label="ContentId" value={contentId} onChange={handleContentId} onKeyDown={onEnterContentId}>
                            </TextField>

                        </Grid>
                        <Grid item md={6} sm={12}>

                            <div style={{ "display": "flex", "flex-direction": "row" }}><Tooltip title="to allow deeplink field"><InfoIcon /></Tooltip>&nbsp;<div>Deeplink Status :</div>&nbsp;
                                <div><Switch
                                    onChange={handleChangeToggle} value={continueToggle} checked={continueToggle}
                                />
                                </div>
                            </div>
                        </Grid>

                        <Grid item md={6}>
                            <TextField variant="outlined" className={classes.inputField} width="25" disabled required label="Content Name" value={displayName} >
                            </TextField>
                        </Grid>



                        <Grid item md={6} sm={12}>

                            <div style={{ "display": "flex", "flex-direction": "row" }}><Tooltip title="to allow external field"><InfoIcon /></Tooltip>&nbsp;<div>External:</div>&nbsp;
                                <div><Switch
                                    onChange={handleExternalToggle} value={externalToggle} checked={externalToggle}
                                />
                                </div>
                            </div>
                        </Grid>

                        <Grid item md={6} sm={12}>
                            <TextField variant="outlined" disabled={continueToggle == false} className={classes.inputField} width="25" required label="Url" name="url" value={url} onChange={onHandleChangeData} >
                            </TextField>

                        </Grid>

                        {/* //added new */}
                        <Grid item md={6}>
                            <TextField variant="outlined" className={classes.inputFieldBucket} width="25" required label="Tag" name="tag" value={englishLang} onChange={(e) => setEnglishLang(e.target.value)} >
                            </TextField>
                            <Button style={{ height: "56px" }} variant="contained" onClick={toggleLanguage}>Multi-Language</Button>
                        </Grid>
                        {/* //end */}



                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"
                            >
                                Image
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => setThumbnail([...thumbnail, ...e.target.files])}
                                />
                            </Button>
                            {thumbnail.length > 0 ?
                                (thumbnail.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteBackground(e, i)} /></p>))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>

                            <div style={{ "display": "flex", "flex-direction": "row" }}><Tooltip title="to allow access of start and end date"><InfoIcon /></Tooltip>&nbsp;<div>set time :</div>&nbsp;
                                <div><Switch
                                    onChange={handleSTToggle} value={st} checked={st}
                                />
                                </div>
                            </div>
                        </Grid>
                        
                        <Grid item md={6} sm={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker label="Start Date" disabled={st == false} className={classes.inputField} value={startDate} onChange={e => setStartDate(e)} ></DateTimePicker>
                                <IconButton
                                    style={{ marginTop: "25px" }}
                                    edge="end"
                                    size="small"
                                    disabled={!date}
                                    onClick={() => setDate(null)}
                                >
                                    <ClearIcon />
                                </IconButton>

                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item md={6} sm={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker label="End Date" disabled={st == false} className={classes.inputField} value={endDate} onChange={e => setEndDate(e)} ></DateTimePicker>
                                <IconButton
                                    style={{ marginTop: "25px" }}
                                    edge="end"
                                    size="small"
                                    disabled={!endDate}
                                    onClick={() => setEndDate(null)}
                                >
                                    <ClearIcon />
                                </IconButton>

                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item md={6} sm={12}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Pro User</InputLabel>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="proUser"
                                    name="proUser"
                                    value={user}
                                    onChange={(e) => setUser(e.target.value)}
                                >
                                    <MenuItem value="0">Free User</MenuItem>
                                    <MenuItem value="1">Pro User</MenuItem>
                                    <MenuItem value="2">Both</MenuItem>

                                </Select>

                            </FormControl>

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                className={classes.upload}
                                variant="outlined"
                                component="label"

                            >
                                mp4 Media
                                <input
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => setStencil([...stencil, ...e.target.files])}
                                />
                            </Button>
                            {stencil.length > 0 ?
                                (stencil.map((ele, i) => <p className="mt-2 mr-4">{ele.name} <CancelIcon onClick={(e) => deleteStencil(e, i)} /></p>))
                                : null}
                        </Grid>
                        <Grid item md={6} sm={12}>

                            <div style={{ "display": "flex", "flex-direction": "row" }}><Tooltip title="allow trailer to play"><InfoIcon /></Tooltip>&nbsp;<div>Play trailer :</div>&nbsp;
                                <div><Switch
                                    onChange={handleplayTrailerToggle} value={playTrailer} checked={playTrailer}
                                />
                                </div>
                            </div>
                        </Grid>

                        <Grid item md={6} sm={12}>

                            <div style={{ "display": "flex", "flex-direction": "row" }}><Tooltip title="to allow trailer on loop"><InfoIcon /></Tooltip>&nbsp;<div>Loop Trailer :</div>&nbsp;
                                <div><Switch
                                    disabled={playTrailer == false} onChange={handleLTToggle} value={lt} checked={lt}
                                />
                                </div>
                            </div>
                        </Grid>

                        <Grid item md={6} sm={12}>

                            <div style={{ "display": "flex", "flex-direction": "row" }}><Tooltip title="to allow trailer with sound"><InfoIcon /></Tooltip>&nbsp;<div>Play With Sound :</div>&nbsp;
                                <div><Switch
                                    disabled={playTrailer == false} onChange={handlePWSToggle} value={pws} checked={pws}
                                />
                                </div>
                            </div>
                        </Grid>

                        <Grid item md={6} sm={12}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-mutiple-checkbox-label">Device</InputLabel>

                                <Select
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    multiple
                                    label="Device"
                                    name="Device"
                                    value={device}
                                    onChange={(e) => setDevice(e.target.value)}
                                    // input={<Input />}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {contentRights.map((name) => (
                                        <MenuItem key={name.id} value={name.title}>
                                            <Checkbox checked={device.indexOf(name.title) > -1} />
                                            <ListItemText primary={name.title} />
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>

                        </Grid>
                    </Grid>



                    <Grid item md={12} className="mt-4">
                        <Button className="mr-2" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                        <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button>

                    </Grid>



                    <MultiLanguageModal modal={modal}
                        toggle={toggle}
                        englishLang={englishLang}
                        hindiLang={hindiLang}
                        marathiLang={marathiLang}
                        kannadaLang={kannadaLang}
                        teluguLang={teluguLang}
                        punjabiLang={punjabiLang}
                        tamilLang={tamilLang}
                        gujaratiLang={gujaratiLang}

                        malayalamLang={malayalamLang}
                        bengaliLang={bengaliLang}
                        odisaLang={odisaLang}
                        urduLang={urduLang}
                        sinhalaLang={sinhalaLang}
                        khmerLang={khmerLang}
                        arabicLang={arabicLang}
                        burmeseLang={burmeseLang}
                        afganiLang={afganiLang}

                        setAfganiLang={setAfganiLang}
                        setBurmeseLang={setBurmeseLang}
                        setEnglishLang={setEnglishLang}
                        setHindiLang={setHindiLang}
                        setMarathiiLang={setMarathiiLang}
                        setKannadaLang={setKannadaLang}
                        setTeluguLang={setTeluguLang}
                        setPunjabiLang={setPunjabiLang}
                        setTamilLang={setTamilLang}
                        setGujaratiLang={setGujaratiLang}
                        setOdisaLang={setOdisaLang}
                        setMalayalamLang={setMalayalamLang}
                        setBengaliLang={setBengaliLang}
                        setUrduLang={setUrduLang}
                        setSinhalaLang={setSinhalaLang}
                        setKhmerLang={setKhmerLang}
                        setArabicLang={setArabicLang}
                    >

                    </MultiLanguageModal>
                </div>

            </Paper>
        </Fragment>
    )
}
